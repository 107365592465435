import { useState, useEffect } from "react";

import './Portfolio.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { SvgDelete, SvgEdit, SvgEye, SvgSearch, SvgSetaDown, SvgSetaRight, SvgSetaUp } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_AltPosition } from "services/RegisterData";

export default function Page_Portfolio(props){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('portfolio'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('portfolio', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('portfolio').forEach(item =>{
                if(item.pt_br_client.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.pt_br_project.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('portfolio'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'portfolio_details')
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "portfolio", "type": "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Home">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Portfólio
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        bloco
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th>Cliente</th>
                            <th width="60">Ano</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.title }</td>
                                        <td>{ key.client }</td>
                                        <td>{ key.year }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <a href={ "https://chroma-site.chroma-garden.com/#preview#" + key.id } data-tooltip-id={ "preview_" + key.id } data-tooltip-content="Visualizar" data-tooltip-place="top" target="_blank">
                                                    <SvgEye color="#324d6b" className="icons"/>
                                                </a>
                                                <Tooltip id={ "preview_" + key.id } />
                                                <div data-tooltip-id={ "remuve_" + key.id } data-tooltip-content="Deletar" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.title) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "remuve_" + key.id } />
                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color={ key.status === 0 ? "#ffffff" : "#324d6b" } className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}