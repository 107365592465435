// Cookies type
// const cookiesServidor        = 'localhost';
const cookiesServidor         = 'chroma-dashboard.chroma-garden.com/';
export const cookiesRegister  = cookiesServidor;

// Cookies name
export const typeCookie       = 'chroma_site_dash';
export const typeCookiePassw  = 'chroma_site_passw';
export const typeCookieEmail  = 'chroma_site_email';
export const typeCookiePage   = 'chroma_site_page';
export const typeCookiePageId = 'chroma_site_page_id';
export const typeCookieNewId  = 'chroma_site_new_id';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'image', 'link', 'align', 'paragraph', 'ul', 'ol', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}

// List month
export const monthList = [
    {
        "name": "Janeiro",
        "month": "01"
    },
    {
        "name": "Fevereiro",
        "month": "02"
    },
    {
        "name": "Março",
        "month": "03"
    },
    {
        "name": "Abril",
        "month": "04"
    },
    {
        "name": "Maio",
        "month": "05"
    },
    {
        "name": "Junho",
        "month": "06"
    },
    {
        "name": "Julho",
        "month": "07"
    },
    {
        "name": "Agosto",
        "month": "08"
    },
    {
        "name": "Setembro",
        "month": "09"
    },
    {
        "name": "Outubro",
        "month": "10"
    },
    {
        "name": "Novembro",
        "month": "11"
    },
    {
        "name": "Dezembro",
        "month": "12"
    }
]

// type pages
export const showPageList = [
    {
        "key": "home",
        "name": {
            "en": "Home",
            "es": "Home",
            "pt_br": "Home"
        }
    },
    {
        "key": "our_services",
        "name": {
            "en": "Our services",
            "es": "Nuestros servicios",
            "pt_br": "Nossos serviços"
        }
    },
    {
        "key": "spotlight",
        "name": {
            "en": "Spotlight",
            "es": "Spotlight",
            "pt_br": "Spotlight"
        }
    },
    {
        "key": "portfolio",
        "name": {
            "en": "Portfolio",
            "es": "Portafolio",
            "pt_br": "Portfólio"
        }
    },
    {
        "key": "clients",
        "name": {
            "en": "Clients",
            "es": "Clientes",
            "pt_br": "Clientes"
        }
    },
    {
        "key": "text",
        "name": {
            "en": "Text",
            "es": "Texto",
            "pt_br": "Texto"
        }
    },
    {
        "key": "show_numbers",
        "name": {
            "en": "Show numbers",
            "es": "Mostrar numeros",
            "pt_br": "Mostrar números"
        }
    },
    {
        "key": "video",
        "name": {
            "en": "Video",
            "es": "Video",
            "pt_br": "Vídeo"
        }
    },
    {
        "key": "contact",
        "name": {
            "en": "contact",
            "es": "Contacto",
            "pt_br": "Contato"
        }
    }
]