import { useState, useEffect } from "react";

import './ShowNumbers.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete } from "components/SvgFile";

export default function ModelPage_ShowNumbers(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]       = useState(InitialData('pt_br_title'));
    const [ ptBrSubtitle, setPtBrSubtitle ] = useState(InitialData('pt_br_subtitle'));
    const [ ptBrContents, setPtBrContents]  = useState(InitialData('pt_br_contents'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_subtitle'));
    const [ enContents, setEnContents]  = useState(InitialData('en_contents'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_subtitle'));
    const [ esContents, setEsContents]  = useState(InitialData('es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        LoadingData();

        // pt_br
        setPtBrTitle(InitialData('pt_br_title'));
        setPtBrSubtitle(InitialData('pt_br_subtitle'));
        setPtBrContents(InitialData('pt_br_contents'));

        // en
        setEnTitle(InitialData('en_title'));
        setEnSubtitle(InitialData('en_subtitle'));
        setEnContents(InitialData('en_contents'));

        // es
        setEsTitle(InitialData('es_title'));
        setEsSubtitle(InitialData('es_subtitle'));
        setEsContents(InitialData('es_contents'));     
    }, [dataPage, idPage]);


    function LoadingData(){
        props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            // data - pt_br
            if(type == 'pt_br_contents'){
                const contents = newData.contents.filter(item => item.language == 'pt_br');
                return contents;
            }
            // data - en
            if(type == 'en_contents'){
                const contents = newData.contents.filter(item => item.language == 'en');
                return contents;
            }
            // data - es
            if(type == 'es_contents'){
                const contents = newData.contents.filter(item => item.language == 'es');
                return contents;
            }
            return newData[type];
        }
        if(type == 'pt_br_contents' || type == 'en_contents' || type == 'es_contents'){
            return [
                { "id": 0, "subtitle": "", "title": "", "text": "" },
                { "id": 0, "subtitle": "", "title": "", "text": "" },
                { "id": 0, "subtitle": "", "title": "", "text": "" },
                { "id": 0, "subtitle": "", "title": "", "text": "" }
            ];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrSubtitle":
                    setPtBrSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": value, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;

            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": value, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;   

            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": value, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": value, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;  

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": value, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;  

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": value, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;
        }
    }

    function HandleDataContents(type, index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index][type] = value;

            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": newData, "en_contents": enContents, "es_contents": esContents });
            setPtBrContents(newData);
        }
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index][type] = value;
            
            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": newData, "es_contents": esContents });
            setEnContents(newData);
        }
        if(idioma == 'es'){
            const newData = [...esContents];
            newData[index][type] = value;
            
            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "en_subtitle": enSubtitle, "en_title": enTitle, "es_subtitle": esSubtitle, "es_title": esTitle, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": newData });
            setEsContents(newData);
        }
    }

    return(
        <div className="ModelPage_Clients">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Anos de experiências
                                    </div>                                  
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" className="year" onChange={ (e)=>{ HandleData('ptBrTitle', e.target.value) } } value={ ptBrTitle } />
                                        <span className="name_input">Ano</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('ptBrSubtitle', e.target.value) } } value={ ptBrSubtitle } />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Demais númerações
                                    </div>                                  
                                </div>
                                {
                                    ptBrContents.map((key, index)=>{
                                        return(      
                                            <div className="list_contents" key={ index }>
                                                <div className="div_input">
                                                    <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('subtitle', index, e.target.value) } } value={ key.subtitle } />
                                                    <span className="name_input">Sigla</span>
                                                </div>
                                                <div className="div_input">
                                                    <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                    <span className="name_input">Número</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleDataContents('text', index, e.target.value) } } value={ key.text } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            Years of experiences
                                        </div>                                  
                                    </div>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <input type="text" className="year" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                            <span className="name_input">Year</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } />
                                            <span className="name_input">Title</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            Too many numbers
                                        </div>                                  
                                    </div>
                                    {
                                        enContents.map((key, index)=>{
                                            return(      
                                                <div className="list_contents" key={ index }>
                                                    <div className="div_input">
                                                        <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('subtitle', index, e.target.value) } } value={ key.subtitle } />
                                                        <span className="name_input">Acronym</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Number</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('text', index, e.target.value) } } value={ key.text } />
                                                        <span className="name_input">Title</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            Años de experiencias
                                        </div>                                  
                                    </div>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <input type="text" className="year" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                            <span className="name_input">Ano</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } />
                                            <span className="name_input">Título</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            demasiados números
                                        </div>                                  
                                    </div>
                                    {
                                        esContents.map((key, index)=>{
                                            return(      
                                                <div className="list_contents" key={ index }>
                                                    <div className="div_input">
                                                        <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('subtitle', index, e.target.value) } } value={ key.subtitle } />
                                                        <span className="name_input">Acrónimo</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="year" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Número</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('text', index, e.target.value) } } value={ key.text } />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
            }
        </div>
    )
}