import { useState, useEffect } from "react";

import './Text.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ModelPage_Text(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]       = useState(InitialData('pt_br_title'));
    const [ ptBrSubtitle, setPtBrSubtitle ] = useState(InitialData('pt_br_subtitle'));
    const [ ptBrText, setPtBrText ]         = useState(InitialData('pt_br_text'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_subtitle'));
    const [ enText, setEnText ]         = useState(InitialData('en_text'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_subtitle'));
    const [ esText, setEsText ]         = useState(InitialData('es_text'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        LoadingData();

        // pt_br
        setPtBrTitle(InitialData('pt_br_title'));
        setPtBrSubtitle(InitialData('pt_br_subtitle'));
        setPtBrText(InitialData('pt_br_text'));

        // en
        setEnTitle(InitialData('en_title'));
        setEnSubtitle(InitialData('en_subtitle'));
        setEnText(InitialData('en_text'));

        // es
        setEsTitle(InitialData('es_title'));
        setEsSubtitle(InitialData('es_subtitle'));
        setEsText(InitialData('es_text'));        
    }, [dataPage, idPage]);


    function LoadingData(){
        props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrSubtitle":
                    setPtBrSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": value, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;

            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": value, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;    

            case "ptBrText":
                    setPtBrText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": value, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;


            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": value, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": value, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;     

            case "enText":
                    setEnText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": value, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": value, "es_title": esTitle, "es_text": esText });
                break;  

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": value, "es_text": esText });
                break;
                
            case "esText":
                    setEsText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": value });
                break;
        }
    }

    return(
        <div className="ModelPage_Text">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrSubtitle', e.target.value) } } value={ ptBrSubtitle } />
                                <span className="name_input">Subtítulo</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Título</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrTitle !='' ? ptBrTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrTitle', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrText !='' ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrText', newContent) } />
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } required />
                                    <span className="name_input">Caption</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Title</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enTitle !='' ? enTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enTitle', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Text</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } required />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Título</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esTitle !='' ? esTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esTitle', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                                </div>
                            </div>
                        </>
            }
        </div>
    )
}