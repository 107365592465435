import { useState, useEffect } from "react";

import './Video.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function ModelPage_Video(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]         = useState(InitialData('pt_br_title'));
    const [ ptBrSubtitle, setPtBrSubtitle ]   = useState(InitialData('pt_br_subtitle'));
    const [ ptBrTypeVideo, setPtBrTypeVideo ] = useState(InitialData('pt_br_type_video'));
    const [ ptBrFile, setPtBrFile ]           = useState(InitialData('pt_br_file'));

    // en
    const [ enTitle, setEnTitle ]         = useState(InitialData('en_title'));
    const [ enSubtitle, setEnSubtitle ]   = useState(InitialData('en_subtitle'));
    const [ enTypeVideo, setEnTypeVideo ] = useState(InitialData('en_type_video'));
    const [ enFile, setEnFile ]           = useState(InitialData('en_file'));

    // es
    const [ esTitle, setEsTitle ]         = useState(InitialData('es_title'));
    const [ esSubtitle, setEsSubtitle ]   = useState(InitialData('es_subtitle'));
    const [ esTypeVideo, setEsTypeVideo ] = useState(InitialData('es_type_video'));
    const [ esFile, setEsFile ]           = useState(InitialData('es_file'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        LoadingData();

        // pt_br
        setPtBrTitle(InitialData('pt_br_title'));
        setPtBrSubtitle(InitialData('pt_br_subtitle'));
        setPtBrTypeVideo(InitialData('pt_br_type_video'));
        setPtBrFile(InitialData('pt_br_file'));

        // en
        setEnTitle(InitialData('en_title'));
        setEnSubtitle(InitialData('en_subtitle'));
        setEnTypeVideo(InitialData('en_type_video'));
        setEnFile(InitialData('en_file'));

        // es
        setEsTitle(InitialData('es_title'));
        setEsSubtitle(InitialData('es_subtitle'));
        setEsTypeVideo(InitialData('es_type_video'));
        setEsFile(InitialData('es_file'));        
    }, [dataPage, idPage]);


    function LoadingData(){
        props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo, "es_file": esFile });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'pt_br_type_video' || type == 'en_type_video' || type == 'es_type_video'){
                return { "value": newData[type], "label": newData[type] }
            }
            return newData[type];
        }
        if(type == 'pt_br_type_video' || type == 'en_type_video' || type == 'es_type_video'){
            return { "value": "", "label": "#" }
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {                
            case "ptBrSubtitle":
                    setPtBrSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": value, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;

            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": value, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;

            case "ptBrTypeVideo":
                    setPtBrTypeVideo(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": value.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;
                
            case "ptBrFile":
                    setPtBrFile(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": value, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;
                
            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": value, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": value, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;

            case "enTypeVideo":
                    setEnTypeVideo(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": value.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;
                
            case "enFile":
                    setEnFile(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": value, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;
                
            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": value, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;
                
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": value, "es_type_video": esTypeVideo.value, "es_file": esFile });
                break;

            case "esTypeVideo":
                    setEsTypeVideo(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": value.value, "es_file": esFile });
                break;
                
            case "esFile":
                    setEsFile(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_type_video": ptBrTypeVideo.value, "pt_br_file": ptBrFile, "en_subtitle": enSubtitle, "en_title": enTitle, "en_type_video": enTypeVideo.value, "en_file": enFile, "es_subtitle": esSubtitle, "es_title": esTitle, "es_type_video": esTypeVideo.value, "es_file": value });
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelPage_Video">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input">
                                <Select className="select_type_video" defaultOptions options={[{ "value": "", "label": "#" }, { "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { HandleData('ptBrTypeVideo', e) } } value={ ptBrTypeVideo } placeholder="..." />
                                <span className="name_input">Origem do vídeo</span>
                            </div>
                            <div className="div_input">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrFile', e.target.value) } } value={ ptBrFile.replaceAll(' ', '') } required />
                                <span className="name_input">Vídeo</span>
                            </div>                            
                            {
                                ptBrFile == '' ? null :
                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(ptBrTypeVideo.value, ptBrFile) } }>
                                    {
                                        ptBrTypeVideo.value == 'Vimeo' ? 
                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                            ptBrTypeVideo.value == 'Youtube' ? <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                    }
                                </div>
                            }
                            
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrSubtitle', e.target.value) } } value={ ptBrSubtitle } />
                                <span className="name_input">Subtítulo</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrTitle !='' ? ptBrTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrTitle', newContent) } />
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                        <>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <Select className="select_type_video" defaultOptions options={[{ "value": "", "label": "#" }, { "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { HandleData('enTypeVideo', e) } } value={ enTypeVideo } placeholder="..." />
                                    <span className="name_input">Video source</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('enFile', e.target.value) } } value={ enFile.replaceAll(' ', '') } required />
                                    <span className="name_input">Video</span>
                                </div>                           
                                {
                                    enFile == '' ? null :
                                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(enTypeVideo.value, enFile) } }>
                                        {
                                            enTypeVideo.value == 'Vimeo' ? 
                                            <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                enTypeVideo.value == 'Youtube' ? <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                        }
                                    </div>
                                }
                            
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } />
                                    <span className="name_input">Caption</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Title</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enTitle !='' ? enTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enTitle', newContent) } />
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <Select className="select_type_video" defaultOptions options={[{ "value": "", "label": "#" }, { "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { HandleData('esTypeVideo', e) } } value={ esTypeVideo } placeholder="..." />
                                    <span className="name_input">Fuente de vídeo</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('esFile', e.target.value) } } value={ esFile.replaceAll(' ', '') } required />
                                    <span className="name_input">Vídeo</span>
                                </div>                           
                                {
                                    esFile == '' ? null :
                                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(esTypeVideo.value, esFile) } }>
                                        {
                                            esTypeVideo.value == 'Vimeo' ? 
                                            <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                esTypeVideo.value == 'Youtube' ? <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                        }
                                    </div>
                                }

                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Título</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esTitle !='' ? esTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esTitle', newContent) } />
                                </div>
                            </div>
                        </>
            }
        </div>
    )
}