import { useState, useEffect } from "react";

import './Clients.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function ModelPage_Clients(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]       = useState(InitialData('pt_br_title'));
    const [ ptBrSubtitle, setPtBrSubtitle ] = useState(InitialData('pt_br_subtitle'));
    const [ ptBrText, setPtBrText ]         = useState(InitialData('pt_br_text'));
    const [ ptBrContents, setPtBrContents]  = useState(InitialData('pt_br_contents'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_subtitle'));
    const [ enText, setEnText ]         = useState(InitialData('en_text'));
    const [ enContents, setEnContents]  = useState(InitialData('en_contents'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_subtitle'));
    const [ esText, setEsText ]         = useState(InitialData('es_text'));
    const [ esContents, setEsContents]  = useState(InitialData('es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        LoadingData();

        // pt_br
        setPtBrTitle(InitialData('pt_br_title'));
        setPtBrSubtitle(InitialData('pt_br_subtitle'));
        setPtBrText(InitialData('pt_br_text'));
        setPtBrContents(InitialData('pt_br_contents'));

        // en
        setEnTitle(InitialData('en_title'));
        setEnSubtitle(InitialData('en_subtitle'));
        setEnText(InitialData('en_text'));
        setEnContents(InitialData('en_contents'));

        // es
        setEsTitle(InitialData('es_title'));
        setEsSubtitle(InitialData('es_subtitle'));
        setEsText(InitialData('es_text'));   
        setEsContents(InitialData('es_contents'));     
    }, [dataPage, idPage]);

    function LoadingData(){
        props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            // data - pt_br
            if(type == 'pt_br_contents'){
                const contents = newData.contents.filter(item => item.language == 'pt_br');
                return contents;
            }
            // data - en
            if(type == 'en_contents'){
                const contents = newData.contents.filter(item => item.language == 'en');
                return contents;
            }
            // data - es
            if(type == 'es_contents'){
                const contents = newData.contents.filter(item => item.language == 'es');
                return contents;
            }
            return newData[type];
        }
        if(type == 'pt_br_contents' || type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrSubtitle":
                    setPtBrSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": value, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;

            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": value, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;    

            case "ptBrText":
                    setPtBrText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": value, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;


            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": value, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": value, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;     

            case "enText":
                    setEnText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": value, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": value, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;  

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": value, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;
                
            case "esText":
                    setEsText(value);
                    props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": value, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": esContents });
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function HandleDataContents(type, index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": newData, "en_contents": enContents, "es_contents": esContents });
            setPtBrContents(newData);
        }
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": newData, "es_contents": esContents });
            setEnContents(newData);
        }
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData({ "pt_br_subtitle": ptBrSubtitle, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "pt_br_contents": ptBrContents, "en_contents": enContents, "es_contents": newData });
            setEsContents(newData);
        }
    }

    function NewContent(){
        if(idioma == 'pt_br'){
            setPtBrContents([...ptBrContents, { "id": 0, "language": "pt_br", "title": "", "file": "", "status": false }]);           
        }
        
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "language": "en", "title": "", "file": "", "status": false }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "language": "es", "title": "", "file": "", "status": false }]);              
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){
            if(idioma == 'pt_br'){
                const newData = [...ptBrContents];
                newData.splice(index, 1);
                setPtBrContents(newData);        
            }
            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": props.origin, "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    return(
        <div className="ModelPage_Clients">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrSubtitle', e.target.value) } } value={ ptBrSubtitle } />
                                <span className="name_input">Subtítulo</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Título</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrTitle !='' ? ptBrTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrTitle', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrText !='' ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrText', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Logotipos
                                    </div>
                                    <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                        <span className="icons_menu">+</span> Logotipo
                                    </div>                                   
                                </div>
                                {
                                    ptBrContents.map((key, index)=>{
                                        return(      
                                            <div className="list_contents" key={ index }>
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input space_top">
                                                    <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { key.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                            <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    key.file  ?
                                                        key.status == true ?
                                                        <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons_img"/>
                                                        </div> :
                                                        <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "logo_clients/" + key.file) } }>
                                                            <SvgImg color="#324d6b" className="icons_img"/>
                                                        </div>
                                                    : null
                                                }
                                                <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } required />
                                    <span className="name_input">Caption</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Title</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enTitle !='' ? enTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enTitle', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Text</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            Logotipos
                                        </div>
                                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                            <span className="icons_menu">+</span> Logotipo
                                        </div>                                   
                                    </div>
                                    {
                                        enContents.map((key, index)=>{
                                            return(      
                                                <div className="list_contents" key={ index }>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Title</span>
                                                    </div>
                                                    <div className="div_input space_top">
                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.status == true ? "Image added" : "Add image" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.status == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "logo_clients/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                    <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } required />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Título</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esTitle !='' ? esTitle.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esTitle', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="div_subtitle_add">
                                        <div className="">
                                            Logotipos
                                        </div>
                                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                            <span className="icons_menu">+</span> Logotipo
                                        </div>                                   
                                    </div>
                                    {
                                        esContents.map((key, index)=>{
                                            return(      
                                                <div className="list_contents" key={ index }>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                    <div className="div_input space_top">
                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.status == true ? "Imagen añadida" : "Añadir imagen" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.status == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "logo_clients/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                    <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
            }
        </div>
    )
}