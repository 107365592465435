import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgAddData, SvgBehance, SvgDelete, SvgEdit, SvgGoogleDrive, SvgImg, SvgLink_opt2, SvgOpt_1, SvgOpt_2, SvgOpt_3, SvgOpt_4, SvgOpt_5, SvgReturnCircle, SvgSave, SvgSetaRight, SvgSite, SvgText, SvgText_Center, SvgText_Left, SvgText_Right, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { Reg_RegisterPortfolio } from "services/RegisterData";

export default function Page_Portfolio_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("portfolio"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ color, setColor ]         = useState(InitialData('color'));
    const [ client, setClient ]       = useState(InitialData('client'));
    const [ year, setYear ]           = useState(InitialData('year'));
    const [ thumbnail, setThumbnail ] = useState(InitialData('thumbnail'));
    const [ statusThumbnail, setStatusThumbnail ] = useState(false);

    // pt_br
    const [ ptBrId, setPtBrId ]               = useState(InitialData('pt_br', 'id'));    
    const [ ptBrProject, setPtBrProject ]     = useState(InitialData('pt_br', 'project'));
    const [ ptBrSubtitle, setPtBrSubtitle ]   = useState(InitialData('pt_br', 'subtitle'));
    const [ ptBrType, setPtBrType ]           = useState(InitialData('pt_br', 'type'));
    const [ ptBrText, setPtBrText ]           = useState(InitialData('pt_br', 'text'));
    const [ ptBrFile, setPtBrFile ]           = useState(InitialData('pt_br', 'file'));
    const [ ptBrDatasheet, setPtBrDatasheet ] = useState(InitialData('pt_br', 'datasheet'));
    const [ ptBrContents, setPtBrContents ]   = useState(InitialData('pt_br', 'contents'));

    const [ ptBrStatusFile, setPtBrStatusFile ] = useState(false);
    const [ ptBrPositionX, setPtBrPositionX ]   = useState('');
    const [ ptBrPositionY, setPtBrPositionY ]   = useState('');
    const [ ptBrWidth, setPtBrWidth ]           = useState('');
    const [ ptBrHeight, setPtBrHeight ]         = useState('');
    const [ ptBrTypeCrop, setPtBrTypeCrop ]     = useState('');
    const [ ptBrShowWidth, setPtBrShowWidth ]   = useState('');
    const [ ptBrWidthReal, setPtBrWidthReal ]   = useState('');
    
    // en
    const [ enId, setEnId ]               = useState(InitialData('en', 'id'));
    const [ enProject, setEnProject ]     = useState(InitialData('en', 'project'));
    const [ enSubtitle, setEnSubtitle ]   = useState(InitialData('en', 'subtitle'));
    const [ enType, setEnType ]           = useState(InitialData('en', 'type'));
    const [ enText, setEnText ]           = useState(InitialData('en', 'text'));
    const [ enFile, setEnFile ]           = useState(InitialData('en', 'file'));
    const [ enDatasheet, setEnDatasheet ] = useState(InitialData('en', 'datasheet'));
    const [ enContents, setEnContents ]   = useState(InitialData('en', 'contents'));
    
    const [ enStatusFile, setEnStatusFile ] = useState(false);
    const [ enPositionX, setEnPositionX ]   = useState('');
    const [ enPositionY, setEnPositionY ]   = useState('');
    const [ enWidth, setEnWidth ]           = useState('');
    const [ enHeight, setEnHeight ]         = useState('');
    const [ enTypeCrop, setEnTypeCrop ]     = useState('');
    const [ enShowWidth, setEnShowWidth ]   = useState('');
    const [ enWidthReal, setEnWidthReal ]   = useState('');
    
    // es
    const [ esId, setEsId ]               = useState(InitialData('es', 'id'));
    const [ esProject, setEsProject ]     = useState(InitialData('es', 'project'));
    const [ esSubtitle, setEsSubtitle ]   = useState(InitialData('es', 'subtitle'));
    const [ esType, setEsType ]           = useState(InitialData('es', 'type'));
    const [ esText, setEsText ]           = useState(InitialData('es', 'text'));
    const [ esFile, setEsFile ]           = useState(InitialData('es', 'file'));
    const [ esDatasheet, setEsDatasheet ] = useState(InitialData('es', 'datasheet'));
    const [ esContents, setEsContents ]   = useState(InitialData('es', 'contents'));

    const [ esStatusFile, setEsStatusFile ] = useState(false);
    const [ esPositionX, setEsPositionX ]   = useState('');
    const [ esPositionY, setEsPositionY ]   = useState('');
    const [ esWidth, setEsWidth ]           = useState('');
    const [ esHeight, setEsHeight ]         = useState('');
    const [ esTypeCrop, setEsTypeCrop ]     = useState('');
    const [ esShowWidth, setEsShowWidth ]   = useState('');
    const [ esWidthReal, setEsWidthReal ]   = useState('');

    const [ namePageIdioma, setNamePageIdioma ] = useState([
        {
            "en": "To save",
            "es": "Ahorrar",
            "pt_br": "Salvar"
        },
        {
            "en": "Fields left blank will not appear on the site. Fields marked with an asterisk (*) are mandatory.",
            "es": "Los campos dejados en blanco no aparecerán en el sitio. Los campos marcados con un asterisco son obligatorios.",
            "pt_br": "Campos deixados em branco não aparecerão no site. Os campos marcados com asterisco (*) são de preenchimento obrigatório"
        },
        {
            "en": "Fixed data",
            "es": "Datos fijos",
            "pt_br": "Dados fixos"
        }
    ]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('portfolio', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setTitle(InitialData('title'));
        setColor(InitialData('color'));
        setClient(InitialData('client'));
        setYear(InitialData('year'));
        setThumbnail(InitialData('thumbnail'));
        setStatusThumbnail(false);

        // pt_br
        setPtBrId(InitialData('pt_br', 'id'));
        setPtBrProject(InitialData('pt_br', 'project'));
        setPtBrSubtitle(InitialData('pt_br', 'subtitle'));
        setPtBrType(InitialData('pt_br', 'type'));
        setPtBrText(InitialData('pt_br', 'text'));
        setPtBrFile(InitialData('pt_br', 'file'));
        setPtBrDatasheet(InitialData('pt_br', 'datasheet'));
        setPtBrContents(InitialData('pt_br', 'contents'));

        setPtBrStatusFile(false);
        setPtBrPositionX('');
        setPtBrPositionY('');
        setPtBrWidth('');
        setPtBrHeight('');
        setPtBrTypeCrop('');
        setPtBrShowWidth('');
        setPtBrWidthReal('');
        
        // en
        setEnId(InitialData('en', 'id'));
        setEnProject(InitialData('en', 'project'));
        setEnSubtitle(InitialData('en', 'subtitle'));
        setEnType(InitialData('en', 'type'));
        setEnText(InitialData('en', 'text'));
        setEnFile(InitialData('en', 'file'));
        setEnDatasheet(InitialData('en', 'datasheet'));
        setEnContents(InitialData('en', 'contents'));

        setEnStatusFile(false);
        setEnPositionX('');
        setEnPositionY('');
        setEnWidth('');
        setEnHeight('');
        setEnTypeCrop('');
        setEnShowWidth('');
        setEnWidthReal('');
        
        // es
        setEsId(InitialData('es', 'id'));
        setEsProject(InitialData('es', 'project'));
        setEsSubtitle(InitialData('es', 'subtitle'));
        setEsType(InitialData('es', 'type'));
        setEsText(InitialData('es', 'text'));
        setEsFile(InitialData('es', 'file'));
        setEsDatasheet(InitialData('es', 'datasheet'));
        setEsContents(InitialData('es', 'contents'));

        setEsStatusFile(false);
        setEsPositionX('');
        setEsPositionY('');
        setEsWidth('');
        setEsHeight('');
        setEsTypeCrop('');
        setEsShowWidth('');
        setEsWidthReal('');
    }, [idPage, dataPage]);

    function InitialData(type, type_1){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'year' || type == 'thumbnail' || type == 'client' || type == 'title' || type == 'color'){
                return newData[type];
            }else {
                const showDataIdioma = newData['data'].find(item => item.idioma == type);
                return showDataIdioma[type_1];
            }
        }
        if(type == 'year' || type == 'thumbnail' || type == 'client' || type == 'title' || type == 'color'){
            return '';
        }else {
            if(type_1 == 'contents'){
                return [];
            }
        }
        return '';
    }

    function ClickIdioma(value){
        setIdioma(value);
        SetListPag('idioma', value);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function PopUpCropCover(value){
        SetModalData('PhotoCrop', { "origin": "cover", "qtd": 1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
        SetModalState('PhotoCrop', true);
    }

    function PopUpCrop(index, index_1, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];        
            SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['data_contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
            SetModalState('PhotoCrop', true);

        }else if(idioma == 'en'){
            const newData = [...enContents];        
            SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['data_contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
            SetModalState('PhotoCrop', true);

        }else if(idioma == 'es'){
            const newData = [...esContents];        
            SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['data_contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
            SetModalState('PhotoCrop', true);
        }
    }

    function RegisterCropData(img){
        if(idioma == 'pt_br'){
            if(img.type == "cover"){    
                setPtBrStatusFile(true);
                setPtBrFile(img.file);
                setPtBrPositionX(img.positionX);
                setPtBrPositionY(img.positionY);
                setPtBrWidth(img.width);
                setPtBrHeight(img.height);
                setPtBrTypeCrop(img.typeCrop);
                setPtBrShowWidth(img.showWidth);
                setPtBrWidthReal(img.widthReal); 

            }else {
                const newData = [...ptBrContents];
                newData[img.index]['data_contents'][img.index_1]['statusFile'] = true;
                newData[img.index]['data_contents'][img.index_1]['file']      = img.file;
                newData[img.index]['data_contents'][img.index_1]['positionX'] = img.positionX;
                newData[img.index]['data_contents'][img.index_1]['positionY'] = img.positionY;
                newData[img.index]['data_contents'][img.index_1]['width']     = img.width;
                newData[img.index]['data_contents'][img.index_1]['height']    = img.height;
                newData[img.index]['data_contents'][img.index_1]['typeCrop']  = img.typeCrop;
                newData[img.index]['data_contents'][img.index_1]['showWidth'] = img.showWidth;
                newData[img.index]['data_contents'][img.index_1]['widthReal'] = img.widthReal;
                setPtBrContents(newData);
            }
        }else if(idioma == 'en'){
            if(img.type == "cover"){
                setEnStatusFile(true);
                setEnFile(img.file);
                setEnPositionX(img.positionX);
                setEnPositionY(img.positionY);
                setEnWidth(img.width);
                setEnHeight(img.height);
                setEnTypeCrop(img.typeCrop);
                setEnShowWidth(img.showWidth);
                setEnWidthReal(img.widthReal);

            }else {
                const newData = [...enContents];
                newData[img.index]['data_contents'][img.index_1]['statusFile'] = true;
                newData[img.index]['data_contents'][img.index_1]['file']      = img.file;
                newData[img.index]['data_contents'][img.index_1]['positionX'] = img.positionX;
                newData[img.index]['data_contents'][img.index_1]['positionY'] = img.positionY;
                newData[img.index]['data_contents'][img.index_1]['width']     = img.width;
                newData[img.index]['data_contents'][img.index_1]['height']    = img.height;
                newData[img.index]['data_contents'][img.index_1]['typeCrop']  = img.typeCrop;
                newData[img.index]['data_contents'][img.index_1]['showWidth'] = img.showWidth;
                newData[img.index]['data_contents'][img.index_1]['widthReal'] = img.widthReal;
                setEnContents(newData);
            }

        }else if(idioma == 'es'){
            if(img.type == "cover"){
                setEsStatusFile(true);
                setEsFile(img.file);
                setEsPositionX(img.positionX);
                setEsPositionY(img.positionY);
                setEsWidth(img.width);
                setEsHeight(img.height);
                setEsTypeCrop(img.typeCrop);
                setEsShowWidth(img.showWidth);
                setEsWidthReal(img.widthReal);

            }else {
                const newData = [...esContents];
                newData[img.index]['data_contents'][img.index_1]['statusFile'] = true;
                newData[img.index]['data_contents'][img.index_1]['file']      = img.file;
                newData[img.index]['data_contents'][img.index_1]['positionX'] = img.positionX;
                newData[img.index]['data_contents'][img.index_1]['positionY'] = img.positionY;
                newData[img.index]['data_contents'][img.index_1]['width']     = img.width;
                newData[img.index]['data_contents'][img.index_1]['height']    = img.height;
                newData[img.index]['data_contents'][img.index_1]['typeCrop']  = img.typeCrop;
                newData[img.index]['data_contents'][img.index_1]['showWidth'] = img.showWidth;
                newData[img.index]['data_contents'][img.index_1]['widthReal'] = img.widthReal;
                setEsContents(newData);
            }
        }
    }

    function AddNewBlock(){
        // pt_br
        if(idioma == 'pt_br'){
            setPtBrContents([...ptBrContents, { "id": 0, "type": 0, "container": false, "status": false, "height": "center", "width": "left", "data_contents": [ ] }]);

        }
        // en
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "type": 0, "container": false, "status": false, "height": "center", "width": "left", "data_contents": [ ] }]);

        }
        // es
        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "type": 0, "container": false, "status": false, "height": "center", "width": "left", "data_contents": [ ] }]);
        }
    }

    function StatusContainer(index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].container = value;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].container = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].container = value;
            setEsContents(newData);
        }
    }

    function StatusAlign(index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].status = value;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].status = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].status = value;
            setEsContents(newData);
        }
    }

    function AltAlign(index, height, width){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].height = height;
            newData[index].width  = width;
            newData[index].status = false;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].height = height;
            newData[index].width  = width;
            newData[index].status  = false;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].height = height;
            newData[index].width  = width;
            newData[index].status  = false;
            setEsContents(newData);
        }
    }

    function RemuveContent(index, id){
        if(idioma == 'pt_br'){
            if(id == 0){
                const newData = [...ptBrContents];
                newData.splice(index, 1);
                setPtBrContents(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Conteúdo" });
                SetModalState('Confirmation', true);
            }

        }else if(idioma == 'en'){
            if(id == 0){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Content" });
                SetModalState('Confirmation', true);
            }

        }else if(idioma == 'es'){
            if(id == 0){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Conteúdo" });
                SetModalState('Confirmation', true);
            }
        }
    }

    function ShowTypeAdd(index, qtd){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].type = qtd;
            for (let i = 0; i < qtd; i++) {
                newData[index].data_contents.push({ "id": 0, "type": "", "status": false, "height": "top", "width": "left"});
            }
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].type = qtd;
            for (let i = 0; i < qtd; i++) {
                newData[index].data_contents.push({ "id": 0, "type": "", "status": false, "height": "top", "width": "left"});
            }
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].type = qtd;
            for (let i = 0; i < qtd; i++) {
                newData[index].data_contents.push({ "id": 0, "type": "", "status": false, "height": "top", "width": "left"});
            }
            setEsContents(newData);
        }
    }

    function NewDataContents(type, index, index_1){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].data_contents[index_1].type = type;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].data_contents[index_1].type = type;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].data_contents[index_1].type = type;
            setEsContents(newData);
        }
    }

    function AltTypeContent(index, index_1){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index].data_contents[index_1].type = '';
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].data_contents[index_1].type = '';
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].data_contents[index_1].type = '';
            setEsContents(newData);
        }
    }

    function StateAlignContent(index_0, index_1, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index_0].data_contents[index_1].status = value;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].data_contents[index_1].status = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].data_contents[index_1].status = value;
            setEsContents(newData);
        }
    }

    function AltAlignContent(index_0, index_1, height, width){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index_0].data_contents[index_1].height = height;
            newData[index_0].data_contents[index_1].width  = width;
            newData[index_0].data_contents[index_1].status = false;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].data_contents[index_1].height = height;
            newData[index_0].data_contents[index_1].width  = width;
            newData[index_0].data_contents[index_1].status = false;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].data_contents[index_1].height = height;
            newData[index_0].data_contents[index_1].width  = width;
            newData[index_0].data_contents[index_1].status = false;
            setEsContents(newData);
        }
    }

    function ShowDataType(type, index, index_1, id_content, id_data){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            switch (type) {
                case "img":
                    return(
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['name'] } />
                                <span className="name_input">Nome</span>
                            </div>
                            <div className="div_input space_top">
                                <div className={ newData[index]['data_contents'][index_1]['statusFile'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { newData[index]['data_contents'][index_1]['statusFile'] == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ PopUpCrop(index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                            </div>
                            {
                                newData[index]['data_contents'][index_1]['file']  ?
                                    newData[index]['data_contents'][index_1]['statusFile'] == true ?
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(newData[index]['data_contents'][index_1]['file'])) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div> :
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg', "portfolio/" + newData[index]['data_contents'][index_1]['file']) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div>
                                : null
                            }
                        </div>
                    )

                case "video":
                    return(
                        <>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_video', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['type_video'] } required>
                                        <option value="">#</option>
                                        {/* <option value="Drive">Drive</option> */}
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['title'] } />
                                    <span className="name_input">Título</span>
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('link', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['link'] ? newData[index]['data_contents'][index_1]['link'].replaceAll(' ', '') : "" } required />
                                    <span className="name_input">Link</span>
                                </div>
                                {
                                    newData[index]['data_contents'][index_1]['link'] == '' ? null :
                                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(newData[index]['data_contents'][index_1]['type_video'], newData[index]['data_contents'][index_1]['link']) } }>
                                        {
                                            newData[index]['data_contents'][index_1]['type_video'] == 'Drive' ? 
                                            <SvgGoogleDrive color="#324d6b" className="show_icons_project" /> :
                                                newData[index]['data_contents'][index_1]['type_video'] == 'Vimeo' ?  
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    newData[index]['data_contents'][index_1]['type_video'] == 'Youtube' ? 
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    )

                case "text":
                    return (
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ config } value={ newData[index]['data_contents'][index_1]['text'] ? newData[index]['data_contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                    )
            }

        }else if(idioma == 'en'){
            const newData = [...enContents];
            switch (type) {
                case "img":
                    return(
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['name'] } />
                                <span className="name_input">Name</span>
                            </div>
                            <div className="div_input space_top">
                                <div className={ newData[index]['data_contents'][index_1]['statusFile'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { newData[index]['data_contents'][index_1]['statusFile'] == true ? "Image added" : "Add image" }
                                        <input type="file" onChange={ (e)=>{ PopUpCrop(index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                            </div>
                            {
                                newData[index]['data_contents'][index_1]['file']  ?
                                    newData[index]['data_contents'][index_1]['statusFile'] == true ?
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(newData[index]['data_contents'][index_1]['file'])) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div> :
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg', "portfolio/" + newData[index]['data_contents'][index_1]['file']) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div>
                                : null
                            }
                        </div>
                    )

                case "video":
                    return(
                        <>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_video', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['type_video'] } required>
                                        <option value="">#</option>
                                        {/* <option value="Drive">Drive</option> */}
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Type</span>
                                </div>
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['title'] } />
                                    <span className="name_input">Title</span>
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('link', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['link'] ? newData[index]['data_contents'][index_1]['link'].replaceAll(' ', '') : "" } required />
                                    <span className="name_input">Link</span>
                                </div>
                                {
                                    newData[index]['data_contents'][index_1]['link'] == '' ? null :
                                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(newData[index]['data_contents'][index_1]['type_video'], newData[index]['data_contents'][index_1]['link']) } }>
                                        {
                                            newData[index]['data_contents'][index_1]['type_video'] == 'Drive' ? 
                                            <SvgGoogleDrive color="#324d6b" className="show_icons_project" /> :
                                                newData[index]['data_contents'][index_1]['type_video'] == 'Vimeo' ?  
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    newData[index]['data_contents'][index_1]['type_video'] == 'Youtube' ? 
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    )

                case "text":
                    return (
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ config } value={ newData[index]['data_contents'][index_1]['text'] ? newData[index]['data_contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                    )
            }

        }else if(idioma == 'es'){
            const newData = [...esContents];
            switch (type) {
                case "img":
                    return(
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['name'] } />
                                <span className="name_input">Nombre</span>
                            </div>
                            <div className="div_input space_top">
                                <div className={ newData[index]['data_contents'][index_1]['statusFile'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { newData[index]['data_contents'][index_1]['statusFile'] == true ? "Imagen añadida" : "Añadir imagen" }
                                        <input type="file" onChange={ (e)=>{ PopUpCrop(index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                            </div>
                            {
                                newData[index]['data_contents'][index_1]['file']  ?
                                    newData[index]['data_contents'][index_1]['statusFile'] == true ?
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(newData[index]['data_contents'][index_1]['file'])) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div> :
                                    <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg', "portfolio/" + newData[index]['data_contents'][index_1]['file']) } }>
                                        <SvgImg color="#324d6b" className="icons_project"/>
                                    </div>
                                : null
                            }
                        </div>
                    )

                case "video":
                    return(
                        <>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_video', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['type_video'] } required>
                                        <option value="">#</option>
                                        {/* <option value="Drive">Drive</option> */}
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['title'] } />
                                    <span className="name_input">Título</span>
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_div space_top">
                                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('link', index, index_1, e.target.value) } } value={ newData[index]['data_contents'][index_1]['link'] ? newData[index]['data_contents'][index_1]['link'].replaceAll(' ', '') : "" } required />
                                    <span className="name_input">Link</span>
                                </div>
                                {
                                    newData[index]['data_contents'][index_1]['link'] == '' ? null :
                                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(newData[index]['data_contents'][index_1]['type_video'], newData[index]['data_contents'][index_1]['link']) } }>
                                        {
                                            newData[index]['data_contents'][index_1]['type_video'] == 'Drive' ? 
                                            <SvgGoogleDrive color="#324d6b" className="show_icons_project" /> :
                                                newData[index]['data_contents'][index_1]['type_video'] == 'Vimeo' ?  
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    newData[index]['data_contents'][index_1]['type_video'] == 'Youtube' ? 
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    )

                case "text":
                    return (
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ config } value={ newData[index]['data_contents'][index_1]['text'] ? newData[index]['data_contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                    )
            }
        }
    }

    function HandleDataContents(type, index_0, index_1, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            newData[index_0].data_contents[index_1][type] = value;
            setPtBrContents(newData);

        }else if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].data_contents[index_1][type] = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].data_contents[index_1][type] = value;
            setEsContents(newData);
        }
    }

    function AddThumbnail(value){
        if(value){
            setThumbnail(value);
            setStatusThumbnail(true);
        }else {
            setThumbnail('');
            setStatusThumbnail(false);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        
        Reg_RegisterPortfolio(userId, idPage, client, color, title, year, thumbnail,
            ptBrId, ptBrProject, ptBrSubtitle, ptBrType, ptBrText, ptBrContents, ptBrDatasheet, ptBrFile, ptBrPositionX, ptBrPositionY, ptBrWidth, ptBrHeight, ptBrTypeCrop, ptBrShowWidth, ptBrWidthReal, 
            
            enId, enProject, enSubtitle, enType, enText, enContents, enDatasheet, enFile, enPositionX, enPositionY, enWidth, enHeight, enTypeCrop, enShowWidth, enWidthReal, 
            
            esId, esProject, esSubtitle, esType, esText, esContents, esDatasheet, esFile, esPositionX, esPositionY, esWidth, esHeight, esTypeCrop, esShowWidth, esWidthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'portfolio');
        SetListPag('currentPageId', 'remuve');
    }
    
    return(
        <div className="Page_Portfolio_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Portfólio
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className={ idioma == 'pt_br' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('pt_br'); } }>pt-br</div>
                        <div className={ idioma == 'en' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('en') } }>en</div>
                        <div className={ idioma == 'es' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('es') } }>es</div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                { namePageIdioma[0][idioma] }
                            </div>
                        </button>
                    </div>
                </div>
                <div className="notification">
                    { namePageIdioma[1][idioma] }
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">{ namePageIdioma[2][idioma] }</div>
                    </div>
                    <div className="list_input_data" style={ { marginBottom: '10px' } }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">
                                {
                                    idioma == 'pt_br' ? "Título*" :
                                    idioma == 'en' ? "Title*" : "Título*"
                                }
                            </span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="year" onChange={ (e)=>{ setYear(e.target.value) } } value={ year.replaceAll(/\D/g, '') } maxLength="4" />
                            <span className="name_input">
                                {
                                    idioma == 'pt_br' ? "Ano" :
                                    idioma == 'en' ? "Year" : "Año"
                                }
                            </span>
                        </div>
                        <div className="div_input">
                            <div className={ statusThumbnail == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    {
                                        idioma == 'pt_br' ? 
                                            statusThumbnail == true ? "Imagem adicionada" : "Adicionar imagem"  :
                                        idioma == 'en' ? 
                                            statusThumbnail == true ? "Image added" : "Add image" 
                                        : 
                                            statusThumbnail == true ? "Imagen añadida" : "Añadir imagen"
                                    }
                                    <input type="file" onChange={ (e)=>{ AddThumbnail(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                        </div>
                        {
                            thumbnail ?
                            statusThumbnail == true ?
                                <div className="div_input" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(thumbnail)) } }>
                                    <SvgImg color="#324d6b" className="show_icons"/>
                                </div> :
                                <div className="div_input" onClick={ ()=>{ OpenFile('showImg', "portfolio/thumbnail/" + thumbnail) } }>
                                    <SvgImg color="#324d6b" className="show_icons"/>
                                </div>
                            : null
                        }
                    </div>
                    <div className="list_input_data" style={ { marginBottom: '10px' } }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setClient(e.target.value) } } value={ client } maxLength="140" required />
                            <span className="name_input">
                                {
                                    idioma == 'pt_br' ? "Nome do cliente*" :
                                    idioma == 'en' ? "Client name*" : "Nombre del cliente*"
                                }
                            </span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setColor(e.target.value) } } value={ color } maxLength="20" required />
                            <span className="name_input">
                                {
                                    idioma == 'pt_br' ? "Cor de fundo*" :
                                    idioma == 'en' ? "Background color*" : "Color de fondo*"
                                }
                            </span>
                        </div>
                        <div className="div_input">
                            <div className="show_color" style={ { backgroundColor: color } } />
                        </div>
                    </div>
                </div>

                {
                    idioma == 'pt_br' ? 
                    <>
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Dados de acordo com o idioma</div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setPtBrProject(e.target.value) } } value={ ptBrProject } maxLength="140" required />
                                    <span className="name_input">Nome do projeto*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setPtBrSubtitle(e.target.value) } } value={ ptBrSubtitle } maxLength="60" />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setPtBrType(e.target.value) } } value={ ptBrType } maxLength="60" />
                                    <span className="name_input">Tipo</span>
                                </div>
                            </div>    
                            <div className="list_input_data">   
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Descrição*</div>
                                    <JoditEditor className="text_editor" config={ config } value={ ptBrText ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setPtBrText(newContent) } required />
                                </div>
                            </div>
                        </div>
                        {
                           ptBrFile !='' ?
                           <div className="div_page_cover">
                                <label className="div_page_cover_edit">
                                    <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                    <div>
                                        <SvgEdit color="#ffffff" className="icons" />
                                    </div>
                                </label>
                                {
                                    ptBrStatusFile == true ?
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ URL.createObjectURL(ptBrFile) } className="show_img_cover" />
                                    </div> :
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ "./assets/portfolio/" + ptBrFile } className="show_img_cover" />
                                    </div>
                                }
                            </div>
                            :
                            <label>
                                <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                <div className="div_cover">
                                    <div className="div_cover_icons">
                                        <SvgImg color="#CDD1D6" className="icons" />
                                    </div>
                                    <div className="div_cover_title">Adicione uma capa</div>
                                    <div className="div_cover_explication">
                                        Arraste e solte aqui ou <span className="div_cover_color">procure o arquivo</span>
                                    </div>
                                    <div className="div_cover_desc">sugestão 1920x1080</div>
                                </div>
                            </label>
                        }

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    ptBrContents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Largura total</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" value="true" onChange={ (e)=>{ StatusContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StatusAlign(index, !key.status) } }>
                                                                Alinhar linha
                                                                <SvgText color="#E51D4D" className="icons" />
                                                            </div>
                                                            {
                                                                key.status == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        {
                                                            key.data_contents.length == 0 ?
                                                            <div className="add_project">
                                                                <div className="list_content">
                                                                    <div className="div_add_type">
                                                                        <div className="list_opt">
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                                <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                                <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                                <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                                <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                                <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    key.data_contents.map((key_1, index_1)=>{
                                                                        return(
                                                                            <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                                <div className="list_content">
                                                                                    {
                                                                                        key_1.type == '' ?
                                                                                        <div className="div_add_type">
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgImg color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Imagem
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgText color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Texto
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Vídeo
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="">
                                                                                            <div className="div_alt_data_contents">
                                                                                                <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                    <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                <div className="div_align">
                                                                                                    <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                        <SvgText color="#E51D4D" className="icons" />
                                                                                                    </div>
                                                                                                    {
                                                                                                        key_1.status == true ?
                                                                                                        <div className="list_align">
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Ficha técnica</div>
                            </div>
                            <div className="space_div">
                                <JoditEditor config={ config } value={ ptBrDatasheet ? ptBrDatasheet.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setPtBrDatasheet(newContent) } />
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ? 
                    <>
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Data according to language</div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEnProject(e.target.value) } } value={ enProject } maxLength="140" required />
                                    <span className="name_input">Project name*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEnSubtitle(e.target.value) } } value={ enSubtitle } maxLength="60" />
                                    <span className="name_input">Caption</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEnType(e.target.value) } } value={ enType } maxLength="60" />
                                    <span className="name_input">Type</span>
                                </div>
                            </div>    
                            <div className="list_input_data">   
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Description</div>
                                    <JoditEditor className="text_editor" config={ config } value={ enText ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setEnText(newContent) } required />
                                </div>
                            </div>
                        </div>
                        {
                           enFile !='' ?
                           <div className="div_page_cover">
                                <label className="div_page_cover_edit">
                                    <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                    <div>
                                        <SvgEdit color="#ffffff" className="icons" />
                                    </div>
                                </label>
                                {
                                    enStatusFile == true ?
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ URL.createObjectURL(enFile) } className="show_img_cover" />
                                    </div> :
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ "./assets/portfolio/" + enFile } className="show_img_cover" />
                                    </div>
                                }
                            </div>
                            :
                            <label>
                                <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                <div className="div_cover">
                                    <div className="div_cover_icons">
                                        <SvgImg color="#CDD1D6" className="icons" />
                                    </div>
                                    <div className="div_cover_title">Add a cover</div>
                                    <div className="div_cover_explication">
                                        Drag and drop here or <span className="div_cover_color">look for the file</span>
                                    </div>
                                    <div className="div_cover_desc">suggestion 1920x1080</div>
                                </div>
                            </label>
                        }

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    enContents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Overall width</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StatusContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StatusAlign(index, !key.status) } }>
                                                                Align line
                                                                <SvgText color="#E51D4D" className="icons" />
                                                            </div>
                                                            {
                                                                key.status == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        {
                                                            key.data_contents.length == 0 ?
                                                            <div className="add_project">
                                                                <div className="list_content">
                                                                    <div className="div_add_type">
                                                                        <div className="list_opt">
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                                <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                                <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                                <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                                <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                                <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    key.data_contents.map((key_1, index_1)=>{
                                                                        return(
                                                                            <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                                <div className="list_content">
                                                                                    {
                                                                                        key_1.type == '' ?
                                                                                        <div className="div_add_type">
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgImg color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Image
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgText color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Text
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Video
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="">
                                                                                            <div className="div_alt_data_contents">
                                                                                                <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                    <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                <div className="div_align">
                                                                                                    <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                        <SvgText color="#E51D4D" className="icons" />
                                                                                                    </div>
                                                                                                    {
                                                                                                        key_1.status == true ?
                                                                                                        <div className="list_align">
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Datasheet</div>
                            </div>
                            <div className="space_div">
                                <JoditEditor config={ config } value={ enDatasheet ? enDatasheet.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setEnDatasheet(newContent) } />
                            </div>
                        </div>
                    </> 
                    :
                    <>
                        <div className="show_page_data">  
                            <div className="list_type_data">
                                <div className="type_title">Datos según idioma</div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEsProject(e.target.value) } } value={ esProject } maxLength="140" required />
                                    <span className="name_input">Nombre del proyecto*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEsSubtitle(e.target.value) } } value={ esSubtitle } maxLength="60" />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEsType(e.target.value) } } value={ esType } maxLength="60" />
                                    <span className="name_input">Tipo</span>
                                </div>
                            </div>    
                            <div className="list_input_data">   
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Descripción</div>
                                    <JoditEditor className="text_editor" config={ config } value={ esText ? esText.replaceAll('&#34;', '"') : "" } placeholder="teste" onBlur={ newContent => setEsText(newContent) } required />
                                </div>
                            </div>
                        </div>
                        {
                           esFile !='' ?
                           <div className="div_page_cover">
                                <label className="div_page_cover_edit">
                                    <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                    <div>
                                        <SvgEdit color="#ffffff" className="icons" />
                                    </div>
                                </label>
                                {
                                    esStatusFile == true ?
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ URL.createObjectURL(esFile) } className="show_img_cover" />
                                    </div> :
                                    <div className="div_cover div_cover_img">
                                        <img alt="img" src={ "./assets/portfolio/" + esFile } className="show_img_cover" />
                                    </div>
                                }
                            </div>
                            :
                            <label>
                                <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                <div className="div_cover">
                                    <div className="div_cover_icons">
                                        <SvgImg color="#CDD1D6" className="icons" />
                                    </div>
                                    <div className="div_cover_title">Agregar una cubierta</div>
                                    <div className="div_cover_explication">
                                        Arrastre y suelte aquí o <span className="div_cover_color">busque el archivo</span>
                                    </div>
                                    <div className="div_cover_desc">sugerencia 1920x1080</div>
                                </div>
                            </label>
                        }

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    esContents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Ancho promedio</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StatusContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StatusAlign(index, !key.status) } }>
                                                                Línea de alineación
                                                                <SvgText color="#E51D4D" className="icons" />
                                                            </div>
                                                            {
                                                                key.status == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        {
                                                            key.data_contents.length == 0 ?
                                                            <div className="add_project">
                                                                <div className="list_content">
                                                                    <div className="div_add_type">
                                                                        <div className="list_opt">
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                                <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                                <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                                <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                                <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                                <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    key.data_contents.map((key_1, index_1)=>{
                                                                        return(
                                                                            <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                                <div className="list_content">
                                                                                    {
                                                                                        key_1.type == '' ?
                                                                                        <div className="div_add_type">
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgImg color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Imagen
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgText color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Texto
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Video
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="">
                                                                                            <div className="div_alt_data_contents">
                                                                                                <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                    <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                <div className="div_align">
                                                                                                    <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                        <SvgText color="#E51D4D" className="icons" />
                                                                                                    </div>
                                                                                                    {
                                                                                                        key_1.status == true ?
                                                                                                        <div className="list_align">
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                            </div>
                                                                                                            <div className="align">
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Ficha técnica</div>
                            </div>
                            <div className="space_div">
                                <JoditEditor config={ config } value={ esDatasheet ? esDatasheet.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setEsDatasheet(newContent) } />
                            </div>
                        </div>
                    </>
                }
            </form>
        </div>
    )
}